<template>
  <div>
    <FarmersStatsCommodity />
  </div>
</template>

<script>
import FarmersStatsCommodity from '@/infdb_components/FarmersStatsCommodity.vue'
export default {
  components: {
    FarmersStatsCommodity
  }
}
</script>