export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-account-network', key: 'menu.blank', text: 'Coordinator', link: '/stateCoor' },
    { icon: 'mdi-badge-account', key: 'menu.blank', text: 'Data Clerk Status', link: '/dcStatus' },
    { icon: 'mdi-chart-bar', key: 'menu.blank', text: 'Farmers Stats(LGA)', link: '/farmersStatsLGA' },
    { icon: 'mdi-chart-bar-stacked', key: 'menu.blank', text: 'Farmers Stats(Crop)', link: '/farmersStatsCommodity' },
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/' } 
  ]
}]
