<template>
  <v-container class="py-6">
    <v-form ref="form" v-model="valid" fast-fail>
      <v-card class="pa-3 mx-auto" max-width="500">
        <v-alert
          border="left"
          color="blue"
          dark
        >Welcome! <br/>Because you are logging in for the first time or reseted your password, you will be required to change the default password</v-alert>
      
        <v-card-title class="justify-center">
          <v-divider class="my-1"></v-divider>
          <div class="mx-2">Change Password</div>
          <v-divider class="my-1"></v-divider>
        </v-card-title>
        <v-card-text>
          <v-text-field 
            v-model="old_password" 
            label="Default/Start-Up Password" 
            :type="showIcon ? 'text' : 'password'"
            outlined
            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            required
            @click:append="showIcon = !showIcon"
          ></v-text-field>
          <v-divider class="my-1"></v-divider>
          <v-text-field 
            v-model="new_password" 
            label="New Password"
            :type="showIcon ? 'text' : 'password'"
            outlined
            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            required
            @click:append="showIcon = !showIcon"
          ></v-text-field>
          <v-text-field 
            v-model="confirm_password" 
            label="Confirm Password" 
            :type="showIcon ? 'text' : 'password'"
            outlined
            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="confirmPasswordRules"
            required
            @click:append="showIcon = !showIcon"
          ></v-text-field>
          <v-btn
            block
            class="success"
            x-large
            :disabled="signinBtnStatus"
            @click="signIn"
          >
            {{ signinBtnText }}
          </v-btn>

          <div class="text-center mt-2 text-body-2">
          <!--<router-link to="#">Register</router-link>-->
          <!--<router-link to="#">Forgot your password?</router-link>-->
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
//import router from '../router'
import httpServ from '../services/HTTPService'
export default {
  data: () => ({
    old_password: '',
    new_password: '',
    confirm_password: '', 
    showIcon: false,
    signinBtnStatus: false,
    signinBtnText: 'Sign In',
    snackbar: false,
    snackbartext: '',
    valid:'',
    passwordRules: [
      (value) => !!value || 'Please type password.',
      (value) => (value && value.length >= 8) || 'minimum 8 characters'
    ],
    confirmPasswordRules2: [
      (value) => !!value || 'type confirm password',
      (value) =>
        value === this.new_password || 'The password confirmation does not match.'
    ]
  }),
  computed: {
    confirmPasswordRules() {
      return [
        (value) => !!value || 'Please type conform password.',
        (value) => (value && value.length >= 8) || 'minimum 8 characters',
        (value) => value === this.new_password || 'The password confirmation does not match.'
      ]
    }
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    this.farmerData = httpServ.getSessionJson('farmerData')
  },
  methods: {
    goToHome: function () {
      //window.location.href = httpServ.getHomeUrl()
    },
    signIn: function () {
      if (this.$refs.form.validate()) {
        this.signinBtnStatus = true
        this.signinBtnText = 'Processing ...'

        const loginData = {
          email: this.userData.email,
          permission: this.old_password,
          new_permission: this.new_password
        }

        const url = httpServ.getAuthenUrl('/b-aut/admin/changePassword')
        const resp = httpServ.postFetch(url,loginData)

        resp.then((result) => {
          if (result.status === 200) {
            this.snackbartext = 'Password changed Successfully!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            setTimeout(async () => {
              await this.$router.push({ name: 'signin' })//.catch((err) => { console.log(err.message)})
            }, 500)
          } else {
            this.snackbartext = result.message
            this.snackbar = true
            this.signinBtnStatus = false
            this.signinBtnText = 'Sign In'
          }
        })
      ///
      }
    }
  }
}
</script>
<style scoped>
.ourimage:hover {
  cursor: pointer;
}
</style>
