<template>
  <div>
    <FarmersStatsLGA />
  </div>
</template>

<script>
import FarmersStatsLGA from '@/infdb_components/FarmersStatsLGA.vue'
export default {
  components: {
    FarmersStatsLGA
  }
}
</script>