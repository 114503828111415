<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmers Statistics By LGA</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
      
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>
  
<script>
import Layout from '@/infdb_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
  
export default {
  components: {
    Layout
  },
  data() {
    return {
      snackbar: false,
      snackbartext:'',
      formTitle:'New Commodity Addition',
      valid:'',
      mode:'new',

      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'This table has no record!',

      headers: [
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state'
        },
        { text: 'Number of Farmers', value: 'quantity' },
        { text: 'LGAs covered', value: 'lga' },
        { text: 'Wards Covered', value: 'ward' },
        { text: 'Commodities Covered', value: 'commodity' }
      ],
      tableData: []
    }
  },
  created() {
    this.userData = httpServ.getSessionJson('userData')
    this.getTableData()
  },
  mounted() {},
  methods: {
    getTableData: function() {
      const formUrl = httpServ.getFarmerUrl('/b-aut/sys/farmerStatsLga')
      const resValue = httpServ.makeRequest(formUrl, 'GET', {})
      
      resValue.then((result) => {
        if (result.message === 'Request Ok!') {
          this.tableData = result.data
          this.record_count = 1
        }
        if (result.message === 'No record!') {
          this.record_count = 0
        }
        //console.log(this.tableData)
      })
      this.tableLoading = false
    }
   
  }
}
</script>
  
  <style scoped>
  </style>
