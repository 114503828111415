<template>
  <div>
    <DCStatus />
  </div>
</template>

<script>
import DCStatus from '@/infdb_components/DCStatus.vue'
export default {
  components: {
    DCStatus
  }
}
</script>